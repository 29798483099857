import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Grid,
    Paper,
    CircularProgress,
    Dialog,
    DialogContent
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import stoolIcon from '../assets/stool-icon.png';
import { usePetContext } from '../context/PetContext';
import { useNavigate } from 'react-router-dom';
import StoolAnalysisRecordReport from './StoolAnalysisRecordReport';
import { useTranslations } from '../translations';
import CloseIcon from '@mui/icons-material/Close';
import Loading from './Loading';
const S3_BUCKET_URL = 'https://petalife-stool.s3.us-east-2.amazonaws.com';

function StoolAnalysis() {
    const { userPetInfo, currentLang } = usePetContext();
    const navigate = useNavigate();
    const [stoolRecords, setStoolRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [showKnowMoreDialog, setShowKnowMoreDialog] = useState(false);
    const [backButton, setBackButton] = useState(false);
    const t = useTranslations(currentLang);

    useEffect(() => {
        const fetchStoolRecords = async () => {
            if (!userPetInfo?.ID) return;

            try {
                const response = await fetch('https://yqw1a6igv8.execute-api.us-east-2.amazonaws.com/UAT', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        petID: userPetInfo.ID
                    })
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch stool records');
                }
                if (response.status != 200) {
                    throw new Error(response.body);
                }
                const data = await response.json();
                if (data.statusCode === 404 && data.body === '"No items found with the provided petID."') {
                    setStoolRecords([]);
                    return;
                }
                // Parse the body if it's a string
                if (data.statusCode === 200) {
                    let records;
                    try {
                        records = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
                    } catch (parseError) {
                        console.error('Error parsing data.body:', parseError);
                        records = [];
                    }
                    // Convert records to array and sort by timestamp
                    const recordsArray = Array.isArray(records) ? records : Object.values(records);
                    const sortedRecords = recordsArray.sort((a, b) => {
                        // Timestamps are already in ISO 8601 format: "2025-01-28T02:00:55"
                        const dateObjA = new Date(a.timestamp);
                        const dateObjB = new Date(b.timestamp);

                        return dateObjB - dateObjA;  // Newest first
                    });
                    setStoolRecords(sortedRecords);
                }
            } catch (error) {
                console.error('Error fetching stool records:', error);
                setStoolRecords([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchStoolRecords();
    }, []); // Empty dependency array to run only once

    const handleBack = () => {
        navigate('/home');
    };

    const handleAddClick = () => {
        navigate('/stool-analysis-request');
    };

    const handleOpenDialog = (record) => {
        setSelectedRecord(record);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRecord(null);
    };

    const handleKnowMoreClick = () => {
        setShowKnowMoreDialog(true);
        setBackButton(true);
    };

    const handleCloseKnowMore = () => {
        setShowKnowMoreDialog(false);
        setBackButton(false);
    };

    console.log(userPetInfo)
    // Get current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Array of month names
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get number of days in current month
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

    // Create array for days in month
    const monthRecords = Array(daysInMonth).fill(null).map((_, index) => {
        const day = index + 1;
        // Find matching record for this day from stoolRecords
        const matchingRecord = stoolRecords.find(record => {
            const recordDate = new Date(record.timestamp);
            
            // Match day, month and year
            return recordDate.getDate() === day &&
                recordDate.getMonth() === currentMonth && // Both are 0-based
                recordDate.getFullYear() === currentYear;
        });

        return {
            id: day,
            typeNumber: matchingRecord?.typeNumber || null
        };
    });

    return (
        <>
            {isLoading ? (
                <Loading currentLang={currentLang} />
            ) : (
                <Box sx={{ p: 2, bgcolor: '#F5F7FA', minHeight: '100vh' }}>
                    {/* Score Section */}
                    <Paper
                        elevation={0}
                        sx={{
                            bgcolor: '#FFA726',
                            color: 'white',
                            p: 2,
                            borderRadius: 2,
                            position: 'relative',
                            minHeight: '200px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        {/* Back button */}
                        <Box
                            onClick={handleBack}
                            sx={{
                                position: 'absolute',
                                top: 16,
                                left: 16,
                                cursor: 'pointer',
                                '&:hover': {
                                    opacity: 0.8
                                }
                            }}
                        >
                            ←
                        </Box>

                        {/* Content */}
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mt: 4
                        }}>
                            {/* Stool Icon and Score */}
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                mb: 1
                            }}>
                                <Box
                                    component="img"
                                    src={stoolIcon}
                                    sx={{
                                        width: 24,
                                        height: 24,
                                        filter: 'brightness(0) invert(1)' // Makes the icon white
                                    }}
                                />
                                <Typography variant="h6">{t.stoolAnalysis.stoolNumber}</Typography>
                            </Box>

                            {/* Score Number */}
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 'bold',
                                    fontSize: '48px',
                                    my: 2
                                }}
                            >
                                {stoolRecords.length}
                            </Typography>

                            {/* Message */}
                            <Typography
                                sx={{
                                    fontSize: '16px',
                                    mb: 3
                                }}
                            >
                                {t.stoolAnalysis.stoolSlogan}
                            </Typography>

                            {/* Add Button */}
                            <IconButton
                                onClick={handleAddClick}
                                sx={{
                                    position: 'absolute',
                                    bottom: -20,
                                    bgcolor: 'white',
                                    width: 40,
                                    height: 40,
                                    boxShadow: 1,
                                    '&:hover': {
                                        bgcolor: 'rgba(255,255,255,0.9)'
                                    }
                                }}
                            >
                                <AddIcon sx={{ color: '#FFA726' }} />
                            </IconButton>
                        </Box>
                    </Paper>

                    {/* November Records Grid */}
                    <Box sx={{ mt: 4 }}>
                        <Typography
                            variant="h6"
                            sx={{
                                mb: 2,
                                fontWeight: 'medium'
                            }}
                        >
                            {months[currentMonth]} {t.stoolAnalysis.record}
                        </Typography>

                        <Grid
                            container
                            spacing={1}
                            sx={{
                                maxWidth: 'fit-content'
                            }}
                        >
                            {monthRecords.map((record) => (
                                <Grid item key={record.id}>
                                    <Box
                                        sx={{
                                            width: 28,
                                            height: 28,
                                            bgcolor: record.typeNumber ? '#FFA726' : '#877a67',
                                            borderRadius: 2,
                                            transition: 'transform 0.2s',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            color: record.typeNumber ? 'black' : 'transparent',
                                            fontSize: '12px',
                                            fontWeight: 'medium',
                                            '&:hover': {
                                                transform: 'scale(1.1)',
                                                cursor: 'pointer'
                                            }
                                        }}
                                    >
                                        {record.typeNumber}
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    {/* Past Records */}
                    <Box sx={{ mt: 4 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6">{t.stoolAnalysis.yourPastRecord}</Typography>
                            <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary' }}
                                onClick={handleKnowMoreClick}
                            >
                                {t.stoolAnalysis.knowMore}
                            </Typography>
                        </Box>

                        {isLoading ? (
                            <Box sx={{ textAlign: 'center', py: 2 }}>
                                <Typography color="text.secondary">{t.stoolAnalysis.loadingRecords}</Typography>
                            </Box>
                        ) : stoolRecords.length > 0 ? (
                            // Sort records by timestamp and take only the 3 most recent
                            stoolRecords
                                // .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                                .slice(0, 3)
                                .map((record, index) => (
                                    <Paper
                                        key={index}
                                        elevation={0}
                                        sx={{
                                            mb: 2,
                                            p: 2,
                                            bgcolor: 'white',
                                            borderRadius: 3,
                                            display: 'flex',
                                            gap: 2,
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                bgcolor: '#f5f5f5'
                                            }
                                        }}
                                        onClick={() => handleOpenDialog(record)}
                                    >
                                        {/* Image */}
                                        <Box
                                            component="img"
                                            src={`${S3_BUCKET_URL}/${record.fileName}`}
                                            alt="Stool Sample"
                                            sx={{
                                                width: 80,
                                                height: 80,
                                                borderRadius: 2,
                                                objectFit: 'cover',
                                                // filter: 'blur(1px)',
                                                WebkitFilter: 'blur(2px)',
                                            }}
                                        />

                                        {/* Content */}
                                        <Box sx={{ flexGrow: 1 }}>
                                            {/* Date and Time */}
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    fontSize: '1rem',
                                                    fontWeight: 'medium',
                                                    mb: 1
                                                }}
                                            >
                                                {new Date(record.timestamp).toLocaleString('en-GB', {
                                                    year: 'numeric',
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true
                                                }).replace(',', '')}
                                            </Typography>

                                            {/* Type Badge */}
                                            <Box
                                                sx={{
                                                    display: 'inline-flex',
                                                    bgcolor: '#FFA726',
                                                    color: '#000',
                                                    px: 2,
                                                    py: 0.5,
                                                    borderRadius: 5,
                                                    fontSize: '0.9rem',
                                                    fontWeight: 'medium'
                                                }}
                                            >
                                                {t.stoolAnalysis.type} {record.typeNumber}
                                            </Box>
                                        </Box>
                                    </Paper>
                                ))
                        ) : (
                            <Box sx={{ textAlign: 'center', py: 2 }}>
                                <Typography color="text.secondary">{t.stoolAnalysis.noRecords}</Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            )}

            {/* Add Dialog */}
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px 12px 0 0',
                        m: 0,  // Remove default margin
                        width: '100%',  // Ensure full width
                        maxHeight: '100%',  // Allow full height
                        position: 'fixed',  // Fix position
                        // bottom: 0  // Align to bottom
                    }
                }}
            >
                {selectedRecord && (
                    <StoolAnalysisRecordReport
                        // report={selectedRecord.record}
                        typeNumber={selectedRecord.typeNumber}
                        typeDescription={selectedRecord.typeDescription}
                        shapeShort={selectedRecord.shapeShort}
                        shapeExplanation={selectedRecord.shapeExplanation}
                        textureShort={selectedRecord.textureShort}
                        textureExplanation={selectedRecord.textureExplanation}
                        color={selectedRecord.color}
                        analysis={selectedRecord.analysis}
                        image={`${S3_BUCKET_URL}/${selectedRecord.fileName}`}
                        timestamp={selectedRecord.timestamp}
                        satisfaction={selectedRecord.satisfaction}
                        onClose={handleCloseDialog}
                        backButton={backButton}
                    />
                )}
            </Dialog>

            <Dialog
                open={showKnowMoreDialog}
                onClose={handleCloseKnowMore}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px 12px 0 0',
                        m: 0,  // Remove default margin
                        width: '90%',  // Ensure full width
                        maxHeight: '90%',  // Allow full height
                        position: 'fixed',  // Fix position
                        // bottom: 0  // Align to bottom
                    }
                }}
            >
                <DialogContent sx={{ bgcolor: '#F5F7FA', p: 0 }}>
                
                <Box sx={{ p: 0, height: '100%' }}>
                    <Paper elevation={0} sx={{ p: 2, mb: 2, borderRadius: '12px 12px 0 0', bgcolor: '#FFA726' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <IconButton onClick={handleCloseKnowMore} sx={{ color: 'white', p: 0.5 }}>
                                <CloseIcon />
                            </IconButton>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Box component="img" src={stoolIcon} sx={{ width: 24, height: 24, filter: 'brightness(0) invert(1)' }} />
                                <Typography variant="h6" sx={{ fontSize: '1.1rem', color: 'white' }}>
                                    {t.stoolAnalysis.yourPastRecord}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                    {/* Add your dialog content here */}
                    {/* Past Records */}



                    {isLoading ? (
                        <Box sx={{ textAlign: 'center', py: 2 }}>
                            <Typography color="text.secondary">{t.stoolAnalysis.loadingRecords}</Typography>
                        </Box>
                    ) : stoolRecords.length > 0 ? (
                        stoolRecords.map((record, index) => (
                            <Paper
                                key={index}
                                elevation={0}
                                sx={{
                                    mb: 2,
                                    p: 2,
                                    bgcolor: 'white',
                                    borderRadius: 3,
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        bgcolor: '#f5f5f5'
                                    }
                                }}
                                onClick={() => handleOpenDialog(record)}
                            >
                                {/* Image */}
                                <Box
                                    component="img"
                                    src={`${S3_BUCKET_URL}/${record.fileName}`}
                                    alt="Stool Sample"
                                    sx={{
                                        width: 80,
                                        height: 80,
                                        borderRadius: 2,
                                        objectFit: 'cover',
                                        // filter: 'blur(1px)',
                                        WebkitFilter: 'blur(2px)',
                                    }}
                                />

                                {/* Content */}
                                <Box sx={{ flexGrow: 1 }}>
                                    {/* Date and Time */}
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontSize: '1rem',
                                            fontWeight: 'medium',
                                            mb: 1
                                        }}
                                    >
                                        {new Date(record.timestamp).toLocaleString('en-GB', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            hour12: true
                                        }).replace(',', '')}
                                    </Typography>

                                    {/* Type Badge */}
                                    <Box
                                        sx={{
                                            display: 'inline-flex',
                                            bgcolor: '#FFA726',
                                            color: '#000',
                                            px: 2,
                                            py: 0.5,
                                            borderRadius: 5,
                                            fontSize: '0.9rem',
                                            fontWeight: 'medium'
                                        }}
                                    >
                                        {t.stoolAnalysis.type} {record.typeNumber}
                                    </Box>
                                </Box>
                            </Paper>
                        ))
                    ) : (
                        <Box sx={{ textAlign: 'center', py: 2 }}>
                            <Typography color="text.secondary">{t.stoolAnalysis.noRecords}</Typography>
                        </Box>
                    )}

                </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default StoolAnalysis; 