import React, { useState, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Paper, 
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import stoolIcon from '../assets/stool-icon.png';
import { usePetContext } from '../context/PetContext';
import { useTranslations } from '../translations';

function StoolAnalysisRequest() {
  const { userPetInfo, currentLang } = usePetContext();
  const t = useTranslations(currentLang);
  const petID = userPetInfo?.ID;
  const userID = userPetInfo?.userId;
  const navigate = useNavigate();
  const [satisfaction, setSatisfaction] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileInputRef = useRef(null);
  const [additionalQuestion, setAdditionalQuestion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const emojis = [
    { value: 1, emoji: '😢', label: t.stoolAnalysisRequest.veryUnsatisfied },
    { value: 2, emoji: '🙁', label: t.stoolAnalysisRequest.unsatisfied },
    { value: 3, emoji: '😐', label: t.stoolAnalysisRequest.neutral },
    { value: 4, emoji: '😊', label: t.stoolAnalysisRequest.satisfied },
    { value: 5, emoji: '😆', label: t.stoolAnalysisRequest.verySatisfied }
  ];

  const handleBack = () => {
    navigate(-1);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      // Create preview URL
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };
  
  const getHongKongISOTime = () => {
    let date = new Date();
    let options = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false, timeZone: 'Asia/Hong_Kong'
    };
    let formatter = new Intl.DateTimeFormat('en-GB', options);
    let parts = formatter.formatToParts(date);
    let isoDate = `${parts[4].value}-${parts[2].value}-${parts[0].value}`;
    let isoTime = `${parts[6].value}:${parts[8].value}:${parts[10].value}`;
    return `${isoDate}T${isoTime}`;
  };
  const handleAnalysisSubmit = async () => {
    try {
      // Navigate to report page with necessary data
      console.log('selectedImage', selectedImage)
      navigate('/stool-analysis-report', {
        state: {
          petID: petID,
          userID: userID,
          selectedImage: selectedImage,  // Pass the actual file
          timestamp: getHongKongISOTime(),
          satisfaction: satisfaction,
          additionalQuestion: additionalQuestion
        }
      });

    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred. Please try again.');
      setOpenErrorDialog(true);
    }
  };

  // Add error dialog component
  const ErrorDialog = () => (
    <Dialog 
      open={openErrorDialog} 
      onClose={() => setOpenErrorDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: '12px',
          maxWidth: '400px'
        }
      }}
    >
      <DialogTitle sx={{ pb: 1 }}>
        Error
      </DialogTitle>
      <DialogContent>
        <Typography>
          {errorMessage}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 1 }}>
        <Button 
          onClick={() => setOpenErrorDialog(false)}
          variant="contained"
          sx={{
            borderRadius: '8px',
            textTransform: 'none'
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (

    <Box sx={{ 
      bgcolor: '#F5F5F5', 
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    }}>
      {/* Header */}
      <Box 
        sx={{ 
          position: 'relative',
          bgcolor: '#FFA726',
          pt: 1,
          pb: 2,
          px: 2,
          borderBottomLeftRadius: 24,
          borderBottomRightRadius: 24,
          boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        {/* Back Button */}
        <IconButton 
          onClick={handleBack}
          sx={{ 
            position: 'absolute',
            left: 8,
            top: '50%',
            transform: 'translateY(-50%)',
            color: 'white'
          }}
        >
          <ArrowBackIosNewIcon sx={{ fontSize: 20 }} />
        </IconButton>

        {/* Title with Icon */}
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
          }}
        >
          <Box 
            component="img"
            src={stoolIcon}
            sx={{ 
              width: 24,
              height: 24,
              filter: 'brightness(0) invert(1)' // Makes icon white
            }}
          />
          <Typography 
            sx={{ 
              color: 'white',
              fontWeight: 500
            }}
          >
            {t.stoolAnalysisRequest.patentedTool}
          </Typography>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ 
        flex: 1,
        bgcolor: '#F5F5F5',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        mt: 2,
        p: 2,
        display: 'flex',
        flexDirection: 'column'
      }}>
        {/* Upload Section */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            border: '1px solid #eee',
            borderRadius: 4,
            textAlign: 'center',
            mb: 3
          }}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleImageChange}
            accept="image/*"
            style={{ display: 'none' }}
          />
          
          <Box
            onClick={handleImageClick}
            sx={{
              border: '2px dashed #FFA726',
              borderRadius: 2,
              p: 4,
              cursor: 'pointer',
              height: 200,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: 'rgba(255, 167, 38, 0.04)'
              }
            }}
          >
            {previewUrl ? (
              <Box
                component="img"
                src={previewUrl}
                alt="Preview"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            ) : (
              <>
                <CloudUploadIcon sx={{ fontSize: 40, color: '#FFA726', mb: 1 }} />
                <Typography color="textSecondary">
                  {t.stoolAnalysisRequest.uploadHere}
                </Typography>
              </>
            )}
          </Box>
        </Paper>

        {/* Satisfaction Rating */}
        <Box sx={{ mb: 3 }}>
          <Typography 
            sx={{ 
              mb: 2, 
              fontWeight: 500,
              color: '#1A1A1A'
            }}
          >
            {t.stoolAnalysisRequest.satisfactionQuestion}
          </Typography>
          
          <Box 
            sx={{ 
              display: 'flex',
              gap: 2,
              justifyContent: 'center'
            }}
          >
            {emojis.map((item) => (
              <Box
                key={item.value}
                onClick={() => setSatisfaction(item.value)}
                sx={{
                  fontSize: '32px',
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  filter: satisfaction === item.value ? 'none' : 'grayscale(0.5)',
                  opacity: satisfaction === item.value ? 1 : 0.7,
                  transform: satisfaction === item.value ? 'scale(1.2)' : 'scale(1)',
                  '&:hover': {
                    transform: 'scale(1.2)',
                    filter: 'none',
                    opacity: 1
                  }
                }}
              >
                {item.emoji}
              </Box>
            ))}
          </Box>
        </Box>

        {/* Additional Question */}
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ mb: 1, fontWeight: 500 }}>
            {t.stoolAnalysisRequest.additionalQuestion}
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
            <EditNoteIcon sx={{ color: '#666', mt: 1 }} />
            <TextField
              fullWidth
              multiline
              minRows={1}
              maxRows={4}
              value={additionalQuestion}
              onChange={(e) => setAdditionalQuestion(e.target.value)}
              placeholder={t.stoolAnalysisRequest.questionPlaceholder}
              variant="standard"
              sx={{
                '& .MuiInput-root': {
                  fontSize: '14px',
                  color: '#666',
                  lineHeight: '1.5',
                },
                '& .MuiInput-root:before': {
                  borderBottom: '1px solid #e0e0e0'
                },
                '& .MuiInput-root:hover:not(.Mui-disabled):before': {
                  borderBottom: '1px solid #666'
                },
                '& .MuiInputBase-inputMultiline': {
                  overflowY: 'auto',
                  maxHeight: '120px'
                }
              }}
              InputProps={{
                inputProps: {
                  style: { 
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap'
                  }
                }
              }}
            />
          </Box>
        </Box>

        {/* Submit Button */}
        <Button
          onClick={handleAnalysisSubmit}
          fullWidth
          variant="contained"
          disabled={isLoading || (!selectedImage || !satisfaction)}
          sx={{
            bgcolor: '#FFA726',
            color: 'white',
            py: 2,
            borderRadius: 2,
            mt: 'auto',
            '&:hover': {
              bgcolor: '#FF9800'
            },
            '&.Mui-disabled': {
              bgcolor: '#FFE0B2',  // Lighter orange for disabled state
              color: 'rgba(255, 255, 255, 0.7)'
            }
          }}
        >
          {isLoading ? t.common.analyzing : t.stoolAnalysisRequest.requestAnalysis}
        </Button>
      </Box>
      <ErrorDialog />
    </Box>

  );
}

export default StoolAnalysisRequest; 