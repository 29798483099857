import React from 'react';
import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import dogAnimation from '../assets/animations/dog-loading.json';  // You'll need to add this animation file
    
function Loading({ currentLang = 'EN' }) {
    const loadingText = currentLang === 'EN' ? 'Loading...' : '載入中...';
    const loadingSubText = currentLang === 'EN' 
        ? "Please wait while we analyze..." 
        : "請稍候，我們正在分析...";

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: '#FFA726',
                padding: 3
            }}
        >
            <Box
                sx={{
                    width: '200px',
                    height: '200px',
                    mb: 3
                }}
            >
                <Lottie
                    animationData={dogAnimation}
                    loop={true}
                    style={{ width: '100%', height: '100%' }}
                />
            </Box>
            <Typography
                variant="h5"
                sx={{
                    color: 'white',
                    fontWeight: 600,
                    mb: 1
                }}
            >
                {loadingText}
            </Typography>
            {/* <Typography
                sx={{
                    color: 'white',
                    opacity: 0.9,
                    textAlign: 'center'
                }}
            >
                {loadingSubText}
            </Typography> */}
        </Box>
    );
}

export default Loading;
