export const translateText = async (text, targetLang = 'zh-TW') => {
  try {
    // Split text into sentences (split by '. ' to preserve decimal numbers)
    const sentences = text.split('. ').filter(Boolean);
    console.log('sentences', sentences);
    // Translate each sentence
    const translatedSentences = await Promise.all(
      sentences.map(async (sentence) => {
        // Add back the period if it's not the last sentence
        const sentenceToTranslate = sentence.endsWith('.') ? sentence : sentence + '.';
        const response = await fetch(
          `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=${targetLang}&dt=t&q=${encodeURIComponent(sentenceToTranslate)}`
        );
        const data = await response.json();
        return data[0][0][0];
      })
    );

    // Join the translated sentences and replace "凳子" with "糞便"
    const translatedText = translatedSentences.join(' ');
    const finalText = translatedText.replace(/凳子/g, '糞便');
    
    console.log('translatedText:', finalText);
    return finalText;
  } catch (error) {
    console.error('Translation error:', error);
    return text; // fallback to original text
  }
}; 