import React, { useState, useEffect } from 'react';
import { Box, IconButton, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';  
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import stoolIcon from '../assets/stool-icon.png';
import { useTranslations } from '../translations';
import { usePetContext } from '../context/PetContext';
import StoolReportContent from './shared/StoolReportContent';
import { v4 as uuidv4 } from 'uuid';
import AWS from 'aws-sdk';
import Loading from './Loading';



function StoolAnalysisReport() {
    const navigate = useNavigate();
    const location = useLocation();
    const { currentLang } = usePetContext();
    const t = useTranslations(currentLang);
    const [isLoading, setIsLoading] = useState(true);
    const [reportData, setReportData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
      // Add error dialog component
      const ErrorDialog = () => (
        <Dialog 
        open={openErrorDialog} 
        onClose={() => setOpenErrorDialog(false)}
        PaperProps={{
            sx: {
            borderRadius: '12px',
            maxWidth: '400px'
            }
        }}
        >
        <DialogTitle sx={{ pb: 1 }}>
            Error
        </DialogTitle>
        <DialogContent>
            <Typography>
            {errorMessage}
            </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 1 }}>
            <Button 
            onClick={() => setOpenErrorDialog(false)}
            variant="contained"
            sx={{
                borderRadius: '8px',
                textTransform: 'none'
            }}
            >
            OK
            </Button>
        </DialogActions>
        </Dialog>
    );
    const uploadFileToS3 = async (fileName) => {
        // S3 Bucket Name
        const S3_BUCKET = "petalife-stool";
    
        // S3 Region
        const REGION = "us-east-2";
    
        // S3 Credentials
        AWS.config.update({
          accessKeyId: "AKIA4MTWIUQGHONH6DXH",
          secretAccessKey: "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",
        });
        const s3 = new AWS.S3({
          params: { Bucket: S3_BUCKET },
          region: REGION,
        });

        const params = {
          Bucket: S3_BUCKET,
          Key: fileName,
          Body: location.state.selectedImage,
        };
    
        // Uploading file to s3
    
        var upload = s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            // File uploading progress
            console.log(
              "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            );
          })
          .promise();
    
        await upload.then((err, data) => {
          console.log(err);
          // Fille successfully uploaded
          // alert("File uploaded successfully.");
        });
      };
    const petalifeStoolAnalysisAPI = async (messagePayload) => {
        const response = await fetch('https://wrw4al4bxb.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(messagePayload)
        });
        //Return data
        const data = await response.json();
        return data || 'Sorry, I could not process that.';
    };

    useEffect(() => {
        const processAnalysis = async () => {


            if (!location.state) {
                console.log('No location state found, navigating to home');
                navigate('/');
                return;
            }

            setIsLoading(true);
            try {
                // Generate UUID and filename
                const newUuid = uuidv4();
                const newFileName = newUuid + '-' + location.state.selectedImage.name;


                // Upload file to S3
                await uploadFileToS3(newFileName);

                const messagePayload = {
                    uuid: newUuid,
                    petID: location.state.petID,
                    userID: location.state.userID,
                    fileName: newFileName,
                    satisfaction: location.state.satisfaction,
                    additionalQuestion: location.state.additionalQuestion,
                    timestamp: location.state.timestamp
                };

                const response = await petalifeStoolAnalysisAPI(messagePayload);
                
                let parsedResponse;
                try {
                    parsedResponse = typeof response.body === 'string' 
                        ? JSON.parse(response.body)
                        : response.body;

                    if (parsedResponse.error) {
                        console.log('Error in response:', parsedResponse.error);
                        setErrorMessage(parsedResponse.error);
                        setOpenErrorDialog(true);
                        return;
                    }

                    const reportDataObj = {
                        typeNumber: parsedResponse.type.number,
                        typeDescription: parsedResponse.type.description,
                        shapeShort: parsedResponse.shape.short,
                        shapeExplanation: parsedResponse.shape.explanation,
                        textureShort: parsedResponse.texture.short,
                        textureExplanation: parsedResponse.texture.explanation,
                        color: parsedResponse.color,
                        analysis: parsedResponse.analysis,
                        image: URL.createObjectURL(location.state.selectedImage),
                        timestamp: location.state.timestamp,
                        satisfaction: location.state.satisfaction,
                        additionalQuestion: location.state.additionalQuestion
                    };
                    setReportData(reportDataObj);

                } catch (error) {
                    console.error('Error parsing response:', error);
                    setErrorMessage('Error processing the image. Please try again.');
                    setOpenErrorDialog(true);
                }
            } catch (error) {
                console.error('Process error:', error);
                setErrorMessage('An error occurred. Please try again.');
                setOpenErrorDialog(true);
            } finally {
                setIsLoading(false);
            }
        };

        processAnalysis();
    }, []); // Empty dependency array to run only once

    const handleErrorClose = () => {
        setOpenErrorDialog(false);
        navigate('/');
    };

    if (isLoading) {
        return <Loading currentLang={currentLang} />;
    }

    return (
        <Box sx={{ bgcolor: '#f8f9fa', minHeight: '100vh' }}>
            {/* Header */}
            <Paper elevation={0} sx={{ 
                p: 2, 
                mb: 0, 
                borderRadius: '12px 12px 0 0',
                bgcolor: '#FFA726',
                color: 'white',
                position: 'sticky',
                top: 0,
                zIndex: 1000,
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <IconButton onClick={() => navigate('/stool-analysis')} sx={{ color: 'white', p: 0.5 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Box component="img" src={stoolIcon} sx={{ width: 24, height: 24, filter: 'brightness(0) invert(1)' }} />
                        <Typography variant="h6" sx={{ fontSize: '1.1rem' }}>
                            {t.stoolAnalysisReport.patentedTool}
                        </Typography>
                    </Box>
                </Box>
            </Paper>

            {reportData && <StoolReportContent {...reportData} />}
            <ErrorDialog 
                open={openErrorDialog}
                message={errorMessage}
                onClose={handleErrorClose}
            />
        </Box>
    );
}

export default StoolAnalysisReport; 