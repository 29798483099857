export const updatePetInfo = async (petData) => {
    try {
        // Convert FormData to object
        const formDataEntries = {};
        petData.forEach((value, key) => {
            // Format birthday to YYYY-MM-DD
            if (key === 'petBirthday' && value) {
                const date = new Date(value);
                formDataEntries[key] = date.toISOString().split('T')[0]; // Gets YYYY-MM-DD
            } else {
                formDataEntries[key] = value;
            }
        });
        const response = await fetch('https://stbardzz0j.execute-api.us-east-2.amazonaws.com/UAT', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formDataEntries)
        });

        if (!response.ok) {
            throw new Error('Failed to update pet information');
        }
        console.log('response', response);
        return await response.json();
    } catch (error) {
        console.error('Error updating pet:', error);
        throw error;
    }
}; 