export const BREED_LISTS = {
  dog: [
    { chinese_name: '吉娃娃', english_name: 'Chihuahua' },
    { chinese_name: '博美犬', english_name: 'Pomeranian' },
    { chinese_name: '約克夏犬', english_name: 'Yorkshire Terrier' },
    { chinese_name: '馬爾濟斯犬', english_name: 'Maltese' },
    { chinese_name: '比熊犬', english_name: 'Bichon Frise' },
    { chinese_name: '北京犬', english_name: 'Pekingese' },
    { chinese_name: '西施犬', english_name: 'Shih Tzu' },
    { chinese_name: '巴哥犬', english_name: 'Pug' },
    { chinese_name: '日本仲', english_name: 'Japanese Chin' },
    { chinese_name: '玩具貴賓犬', english_name: 'Poodle' },
    { chinese_name: '臘腸犬', english_name: 'Dachshund' },
    { chinese_name: '法國鬥牛犬', english_name: 'French Bulldog' },
    { chinese_name: '迷你雪納瑞', english_name: 'Miniature Schnauzer' },
    { chinese_name: '威爾斯柯基犬', english_name: 'Welsh Corgi' },
    { chinese_name: '斯卡蒂梗', english_name: 'Scottish Terrier' },
    { chinese_name: '巴吉度犬', english_name: 'Basset Hound' },
    { chinese_name: '卡爾犬', english_name: 'Cairn Terrier' },
    { chinese_name: '西高地白梗', english_name: 'West Highland White Terrier' },
    { chinese_name: '柴犬', english_name: 'Shiba Inu' },
    { chinese_name: '邊境牧羊犬', english_name: 'Border Collie' },
    { chinese_name: '比格犬', english_name: 'Beagle' },
    { chinese_name: '松獅犬', english_name: 'Chow Chow' },
    { chinese_name: '雪橇犬', english_name: 'Siberian Husky' },
    { chinese_name: '英國可卡犬', english_name: 'English Cocker Spaniel' },
    { chinese_name: '沙皮狗', english_name: 'Shar Pei' },
    { chinese_name: '澳洲牧羊犬', english_name: 'Australian Shepherd' },
    { chinese_name: '美國斯塔福梗', english_name: 'American Staffordshire Terrier' },
    { chinese_name: '德國牧羊犬', english_name: 'German Shepherd' },
    { chinese_name: '拉布拉多犬', english_name: 'Labrador Retriever' },
    { chinese_name: '金毛尋回犬', english_name: 'Golden Retriever' },
    { chinese_name: '杜賓犬', english_name: 'Doberman' },
    { chinese_name: '阿拉斯加雪橇犬', english_name: 'Alaskan Malamute' },
    { chinese_name: '秋田犬', english_name: 'Akita Inu' },
    { chinese_name: '大丹犬', english_name: 'Great Dane' },
    { chinese_name: '羅威納犬', english_name: 'Rottweiler' },
    { chinese_name: '聖伯納犬', english_name: 'Saint Bernard' },
    { chinese_name: '藏獒', english_name: 'Tibetan Mastiff' },
    { chinese_name: '羅威爾犬', english_name: 'Lowchen' },
    { chinese_name: '帕皮蓬犬', english_name: 'Papillon' },
    { chinese_name: '布魯塞爾獵犬', english_name: 'Brussels Griffon' },
    { chinese_name: '短毛捷克梗', english_name: 'Smooth Fox Terrier' },
    { chinese_name: '哈瓦那犬', english_name: 'Havanese' },
    { chinese_name: '帕爾森羅素梗', english_name: 'Parson Russell Terrier' },
    { chinese_name: '捷克梗犬', english_name: 'Czech Terrier' },
    { chinese_name: '波士頓梗', english_name: 'Boston Terrier' },
    { chinese_name: '紐芬蘭犬', english_name: 'Newfoundland' },
    { chinese_name: '維茨拉犬', english_name: 'Vizsla' },
    { chinese_name: '短毛指標犬', english_name: 'German Shorthaired Pointer' },
    { chinese_name: '伯恩山犬', english_name: 'Bernese Mountain Dog' },
    { chinese_name: '薩摩耶犬', english_name: 'Samoyed' },
    { chinese_name: '新斯科舍尋回犬', english_name: 'Nova Scotia Duck Tolling Retriever' },
    { chinese_name: '愛爾蘭獵狼犬', english_name: 'Irish Wolfhound' },
    { chinese_name: '波爾多獒犬', english_name: 'Dogue de Bordeaux' },
    { chinese_name: '斑點狗', english_name: 'Dalmatian' },
    { chinese_name: '威瑪獵犬', english_name: 'Weimaraner' },
    { chinese_name: '庫瓦茨犬', english_name: 'Kuvasz' },
    { chinese_name: '國玩具梗', english_name: 'American Toy Terrier' },
    { chinese_name: '俄羅斯玩具梗', english_name: 'Russian Toy Terrier' },
    { chinese_name: '布雷頓犬', english_name: 'Brittany Dog' },
    { chinese_name: '捲毛比利時犬', english_name: 'Curly-Coated Belgian Dog' },
    { chinese_name: '短毛布魯塞爾犬', english_name: 'Smooth-Coated Brussels Griffon' },
    { chinese_name: '意大利灰狗', english_name: 'Italian Greyhound' },
    { chinese_name: '西里漢梗', english_name: 'Sealyham Terrier' },
    { chinese_name: '挪威灰狗', english_name: 'Norwegian Elkhound' },
    { chinese_name: '荷蘭小獵犬', english_name: 'Dutch Kooikerhondje' },
    { chinese_name: '捷克梗', english_name: 'Cesky Terrier' },
    { chinese_name: '西班牙水犬', english_name: 'Spanish Water Dog' },
    { chinese_name: '葡萄牙水犬', english_name: 'Portuguese Water Dog' },
    { chinese_name: '澳洲牧牛犬', english_name: 'Australian Cattle Dog' },
    { chinese_name: '安納托利亞牧羊犬', english_name: 'Anatolian Shepherd' },
    { chinese_name: '比利時牧羊犬', english_name: 'Belgian Shepherd' },
    { chinese_name: '加拿大皇家獵犬', english_name: 'Canadian Royal Dog' },
    { chinese_name: '盧森堡牧羊犬', english_name: 'Luxembourg Shepherd' },
    { chinese_name: '大白熊犬', english_name: 'Great Pyrenees' },
    { chinese_name: '唐狗', english_name: 'Mongrel' },
    { chinese_name: '其他', english_name: 'Other' }
  ],
  cat: [
    { chinese_name: '美國短毛貓', english_name: 'American Shorthair' },
    { chinese_name: '英國短毛貓', english_name: 'British Shorthair' },
    { chinese_name: '蘇格蘭折耳貓', english_name: 'Scottish Fold' },
    { chinese_name: '曼島貓', english_name: 'Manx' },
    { chinese_name: '俄羅斯藍貓', english_name: 'Russian Blue' },
    { chinese_name: '孟加拉貓', english_name: 'Bengal' },
    { chinese_name: '埃及貓', english_name: 'Egyptian Mau' },
    { chinese_name: '日本短尾貓', english_name: 'Japanese Bobtail' },
    { chinese_name: '土耳其短毛貓', english_name: 'Turkish Shorthair' },
    { chinese_name: '波米拉貓', english_name: 'Burmilla' },
    { chinese_name: '波斯貓', english_name: 'Persian' },
    { chinese_name: '喜馬拉雅貓', english_name: 'Himalayan' },
    { chinese_name: '挪威森林貓', english_name: 'Norwegian Forest Cat' },
    { chinese_name: '土耳其安哥拉貓', english_name: 'Turkish Angora' },
    { chinese_name: '西伯利亞貓', english_name: 'Siberian' },
    { chinese_name: '緬因貓', english_name: 'Maine Coon' },
    { chinese_name: '拉格多爾貓（布偶貓）', english_name: 'Ragdoll' },
    { chinese_name: '索馬里貓', english_name: 'Somali' },
    { chinese_name: '伯曼貓', english_name: 'Birman' },
    { chinese_name: '加拿大無毛貓（斯芬克斯貓）', english_name: 'Canadian Sphynx' },
    { chinese_name: '彼得無毛貓', english_name: 'Peterbald' },
    { chinese_name: '順毛無毛貓', english_name: 'Donskoy (Russian Hairless)' },
    { chinese_name: '德文卷毛貓', english_name: 'Devon Rex' },
    { chinese_name: '異國短毛貓', english_name: 'Exotic Shorthair' },
    { chinese_name: '美國捲耳貓', english_name: 'American Curl' },
    { chinese_name: '曼基康貓（短腿貓）', english_name: 'Munchkin' },
    { chinese_name: '巴厘貓', english_name: 'Balinese' },
    { chinese_name: '孟買貓', english_name: 'Bombay' },
    { chinese_name: '賽爾凱克卷毛貓', english_name: 'Selkirk Rex' },
    { chinese_name: '東方短毛貓', english_name: 'Oriental Shorthair' },
    { chinese_name: '阿比西尼亞貓', english_name: 'Abyssinian' },
    { chinese_name: '克羅爾貓', english_name: 'Korat' },
    { chinese_name: '查特拉斯貓', english_name: 'Chartreux' },
    { chinese_name: '新加坡貓', english_name: 'Singapura' },
    { chinese_name: '豪放貓（奧西貓）', english_name: 'Ocicat' },
    { chinese_name: '高地折耳貓', english_name: 'Highland Fold' },
    { chinese_name: '玩具虎貓（托伊格貓）', english_name: 'Toyger' },
    { chinese_name: '雪鞋貓', english_name: 'Snowshoe' },
    { chinese_name: '東方長毛貓', english_name: 'Oriental Longhair' },
    { chinese_name: '喵喵貓（瑪麗貓）', english_name: 'Sokoke' },
    { chinese_name: '阿拉伯沙漠貓', english_name: 'Arabian Mau' },
    { chinese_name: '西藏貓', english_name: 'Tibetan Cat' },
    { chinese_name: '波拉利斯貓', english_name: 'Polar Cat' },
    { chinese_name: '烏克蘭無毛貓', english_name: 'Ukrainian Levkoy' },
    { chinese_name: '迷你豹貓', english_name: 'Miniature Leopard Cat' },
    { chinese_name: '賽拉斯貓', english_name: 'Serengeti' },
    { chinese_name: '唐貓', english_name: 'Chinese Domestic Cat' },
    { chinese_name: '其他', english_name: 'Other' }
  ]
};

export const dogBreedsList = BREED_LISTS.dog;
export const catBreedsList = BREED_LISTS.cat; 