import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';
import { useTranslations } from '../../translations';
import { usePetContext } from '../../context/PetContext';
import yellow_orange from '../../assets/stool-color/yellow_orange.png';
import Chocolate_brown from '../../assets/stool-color/Chocolate brown.png';
import green from '../../assets/stool-color/green.png';
import grey from '../../assets/stool-color/grey.png';
import black from '../../assets/stool-color/black.png';
import red_streaks from '../../assets/stool-color/red streak.png';
import white_spots from '../../assets/stool-color/white spot.png';
import { translateText } from '../../utils/translate';
// import Loading from '../Loading';  // Using your custom Loading component


function StoolReportContent({ typeNumber, typeDescription, shapeShort, shapeExplanation, textureShort, textureExplanation, color, analysis, image, timestamp, satisfaction }) {
    const { currentLang } = usePetContext();
    const t = useTranslations(currentLang);
    const [isLoading, setIsLoading] = useState(false);
    const [translatedContent, setTranslatedContent] = useState({
        typeDescription,
        shapeShort,
        shapeExplanation,
        textureShort,
        textureExplanation,
        color,
        analysis
    });

    useEffect(() => {
        const translateContent = async () => {
            if (currentLang === '繁') {
                setIsLoading(true);
                try {
                    const translated = {
                        typeDescription: (await translateText(typeDescription)).replace(/。\s+/g, '。'),
                        shapeShort: (await translateText(shapeShort)).replace(/。/g, ''),
                        shapeExplanation: (await translateText(shapeExplanation)).replace(/。\s+/g, '。'),
                        textureShort: (await translateText(textureShort)).replace(/。/g, ''),
                        textureExplanation: (await translateText(textureExplanation)).replace(/。\s+/g, '。'),
                        color: (await translateText(color)).replace(/。/g, ''),
                        analysis: (await translateText(analysis)).replace(/。\s+/g, '。')
                    };
                    setTranslatedContent(translated);
                } catch (error) {
                    console.error('Translation failed:', error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setTranslatedContent({
                    typeDescription,
                    shapeShort,
                    shapeExplanation,
                    textureShort,
                    textureExplanation,
                    color,
                    analysis
                });
            }
        };

        translateContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Empty dependency array to run only once

    if (isLoading) {
        // return <Loading />;
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
                <CircularProgress sx={{ color: '#FFA726' }} />
            </Box>
        );
    }

    const getColorIcon = (color) => {
        const colorMap = {
            [t.stoolAnalysisReport.orangeYellow]: yellow_orange,
            [t.stoolAnalysisReport.chocolateBrown]: Chocolate_brown,
            [t.stoolAnalysisReport.green]: green,
            [t.stoolAnalysisReport.grey]: grey,
            [t.stoolAnalysisReport.black]: black,
            [t.stoolAnalysisReport.redStreaks]: red_streaks,
            [t.stoolAnalysisReport.whiteSpots]: white_spots
        };

        return colorMap[color] || Chocolate_brown;
    };

    const getSatisfactionEmoji = (satisfaction) => {
        const emojiMap = {
            '1': '😢',
            '2': '🙁',
            '3': '😐',
            '4': '😊',
            '5': '😆'
        };

        return emojiMap[satisfaction] || '😐';
    };

    return (
        <>
            {/* Image and Upload Info */}
            <Paper elevation={0} sx={{ borderRadius: '12px', overflow: 'hidden', mb: 2 }}>
                <Box sx={{ width: '100%', position: 'relative', display: 'flex', justifyContent: 'center', bgcolor: '#f5f5f5' }}>
                    <img
                        src={image}
                        alt={t.stoolAnalysisReport.sampleImage}
                        style={{
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: '300px',
                            objectFit: 'contain',
                            margin: '0 auto'
                        }}
                    />
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                        {t.stoolAnalysisReport.uploadedOn}: {new Date(timestamp).toLocaleString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                        }).replace(',', '')}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {t.stoolAnalysisReport.ownerComment}: {getSatisfactionEmoji(satisfaction)}
                    </Typography>
                </Box>
            </Paper>

            {/* Analysis Content */}

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {/* Type Section */}
                <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                    <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                        🎯 {t.stoolAnalysisReport.type}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#2E7D32', fontWeight: 'bold', mb: 1 }}>
                        {t.stoolAnalysisReport.typePrefix} {typeNumber}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {translatedContent.typeDescription}
                    </Typography>
                </Paper>

                {/* Shape Section */}
                <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                    <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                        ⭕ {t.stoolAnalysisReport.shape}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                        {translatedContent.shapeShort}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {translatedContent.shapeExplanation}
                    </Typography>
                </Paper>

                {/* Texture Section */}
                <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                    <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                        🗒️ {t.stoolAnalysisReport.texture}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                        {translatedContent.textureShort}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {translatedContent.textureExplanation}
                    </Typography>
                </Paper>

                {/* Color Section */}
                <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                    <Typography variant="subtitle2" sx={{ color: '#666', mb: 1 }}>
                        🎨 {t.stoolAnalysisReport.color}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                        <Box
                            component="img"
                            src={getColorIcon(color)}
                            sx={{ width: 24, height: 24, objectFit: 'cover' }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {translatedContent.color}
                        </Typography>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                        {translatedContent.color}
                    </Typography>
                </Paper>

                {/* Analysis Result Section */}
                <Paper elevation={0} sx={{ p: 3, borderRadius: '16px', bgcolor: 'white' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                        {t.stoolAnalysisReport.analysisResult}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {translatedContent.analysis}
                    </Typography>
                </Paper>
            </Box>

        </>
    );
}

export default StoolReportContent; 