import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    TextField,
    Button,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    Avatar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { usePetContext } from '../context/PetContext';
import { BREED_LISTS } from './shared/breedLists';
import { updatePetInfo } from '../api/petalife-edit-pet-api';
import defaultPetImage from '../assets/paw-print.png';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useTranslations } from '../translations';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'dayjs/locale/zh-tw';  // Import Traditional Chinese locale
import 'dayjs/locale/en';     // Import English locale

// Create a custom theme for the date picker
const Theme = createTheme({
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#E78E3A',
                        '&:hover': {
                            backgroundColor: '#F4A460',
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    color: '#E78E3A',
                },
            },
        },
    },
    palette: {
        primary: {
            main: '#E78E3A',
        },
    },
});

function EditPet() {


    const location = useLocation();
    const navigate = useNavigate();
    const { userPetInfo, setUserPetInfo, currentLang } = usePetContext();
    const pet = location.state?.pet || userPetInfo;
    const fileInputRef = useRef(null);
    const t = useTranslations(currentLang);
    // Set locale based on currentLang
    const locale = currentLang === 'EN' ? 'en' : 'zh-tw';

    // Convert the stored date string to a dayjs object
    const initialDate = pet.petBirthday ? dayjs(pet.petBirthday) : null;

    const [formData, setFormData] = useState({
        petName: pet.petName || '',
        selectedPet: pet.selectedPet || '',
        breed: pet.breed || '',
        petGender: pet.petGender || '',
        petBirthday: initialDate,
        weight: pet.weight || ''
    });

    const [selectedImage, setSelectedImage] = useState(
        pet.File_name 
            ? `https://petalife-pet.s3.us-east-2.amazonaws.com/${pet.File_name}`
            : defaultPetImage
    );
    const [previewUrl, setPreviewUrl] = useState(
        pet.File_name
            ? `https://petalife-pet.s3.us-east-2.amazonaws.com/${pet.File_name}`
            : defaultPetImage
    );
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
            setPreviewUrl(URL.createObjectURL(file));
        }
    };

    const handlePhotoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // Helper function to get breed display name
    const getBreedDisplayName = (englishName, petType) => {
        if (currentLang === 'EN') return englishName;

        const breedList = BREED_LISTS[petType] || [];
        const breed = breedList.find(b => b.english_name === englishName);
        return breed ? breed.chinese_name : englishName;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const formDataToSend = new FormData();
            
            // Add all form fields to FormData
            formDataToSend.append('ID', pet.ID);
            formDataToSend.append('petName', formData.petName);
            formDataToSend.append('selectedPet', formData.selectedPet);
            formDataToSend.append('breed', formData.breed);
            formDataToSend.append('petGender', formData.petGender);
            formDataToSend.append('petBirthday', formData.petBirthday?.format('YYYY-MM-DD') || '');
            formDataToSend.append('weight', formData.weight);

            // Handle image the same way as CreatePet
            if (selectedImage) {
                formDataToSend.append('petImage', selectedImage);
            }

            await updatePetInfo(formDataToSend);
            navigate('/');
        } catch (error) {
            console.error('Error updating pet:', error);
            setError('Failed to update pet information');
        } finally {
            setIsLoading(false);
        }
    };

    // Function to check if form data has changed
    const hasChanges = () => {
        // Check if selectedImage is different from the original S3 URL
        const originalImageUrl = pet.File_name 
            ? `https://petalife-pet.s3.us-east-2.amazonaws.com/${pet.File_name}`
            : defaultPetImage;
        const imageChanged = selectedImage !== originalImageUrl;

        return (
            imageChanged ||
            formData.petName !== pet.petName ||
            formData.selectedPet !== pet.selectedPet ||
            formData.breed !== pet.breed ||
            formData.petGender !== pet.petGender ||
            formData.weight !== pet.weight ||
            (formData.petBirthday && pet.petBirthday && 
             formData.petBirthday.format('YYYY-MM-DD') !== pet.petBirthday)
        );
    };

    return (
        <ThemeProvider theme={Theme}>
            <Box sx={{ p: 3, maxWidth: 600, mx: 'auto' }}>
                <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => navigate('/')} sx={{ mr: 2 }}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {t.editPet.editPetInformation}
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit}>
                    {/* Image Upload Section */}
                    <Box sx={{ mb: 3, textAlign: 'center' }}>
                        <input
                            accept="image/*"
                            type="file"
                            id="photo-upload"
                            onChange={handlePhotoChange}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="photo-upload">
                            <Box
                                sx={{
                                    width: 120,
                                    height: 120,
                                    margin: '0 auto',
                                    borderRadius: '50%',
                                    border: '2px dashed #E78E3A',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    overflow: 'hidden',
                                    '&:hover': {
                                        opacity: 0.8
                                    }
                                }}
                            >
                                {selectedImage ? (
                                    <img
                                        src={selectedImage}
                                        alt="Pet preview"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                ) : (
                                    <AddAPhotoIcon sx={{ color: '#E78E3A', fontSize: 40 }} />
                                )}
                            </Box>
                        </label>
                        <Typography variant="caption" sx={{ mt: 1, display: 'block', color: '#666' }}>
                            {currentLang === 'EN' ? 'Click to upload photo' : '點擊上傳照片'}
                        </Typography>
                    </Box>

                    {/* Rest of the form fields */}
                    <TextField
                        fullWidth
                        label={t.editPet.petName}
                        name="petName"
                        value={formData.petName}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                    />
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t.editPet.petType}</InputLabel>
                        <Select
                            name="selectedPet"
                            value={formData.selectedPet}
                            onChange={(e) => {
                                setFormData(prev => ({
                                    ...prev,
                                    selectedPet: e.target.value,
                                    breed: ''  // Reset breed when pet type changes
                                }));
                            }}
                            label={t.editPet.petType}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': { borderColor: '#F4A460' },
                                    '&:hover fieldset': { borderColor: '#E78E3A' },
                                    '&.Mui-focused fieldset': { borderColor: '#E78E3A' },
                                }
                            }}
                        >
                            {[
                                { value: 'dog', icon: '🐕', label: t.createPet.dog },
                                { value: 'cat', icon: '🐱', label: t.createPet.cat },
                                { value: 'rabbit', icon: '🐰', label: t.createPet.rabbit },
                                { value: 'bird', icon: '🐦', label: t.createPet.bird },
                                { value: 'fish', icon: '🐠', label: t.createPet.fish },
                                { value: 'reptile', icon: '🦎', label: t.createPet.reptile }
                            ].map((pet) => (
                                <MenuItem 
                                    key={pet.value} 
                                    value={pet.value}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2
                                    }}
                                >
                                    <Typography>{pet.icon} {pet.label}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* Show breed selection only for dogs and cats */}
                    {!['rabbit', 'bird', 'fish', 'reptile'].includes(formData.selectedPet) && (
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel>{t.editPet.breed}</InputLabel>
                            <Select
                                name="breed"
                                value={formData.breed}
                                onChange={handleChange}
                                label={t.editPet.breed}
                            >
                                {BREED_LISTS[formData.selectedPet]?.map((breed) => (
                                    <MenuItem
                                        key={breed.english_name}
                                        value={breed.english_name}
                                    >
                                        {getBreedDisplayName(breed.english_name, formData.selectedPet)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}

                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel>{t.editPet.petGender}</InputLabel>
                        <Select
                            name="petGender"
                            value={formData.petGender}
                            onChange={handleChange}
                            label={t.editPet.petGender}
                        >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                        </Select>
                    </FormControl>

                    <Box sx={{ mb: 3 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                            <DatePicker
                                label={t.editPet.petBirthday}
                                value={formData.petBirthday}
                                onChange={(newValue) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        petBirthday: newValue
                                    }));
                                }}
                                format="DD/MM/YYYY"
                                maxDate={dayjs()}
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <TextField
                        fullWidth
                        label={t.editPet.weight}
                        name="weight"
                        type="number"
                        value={formData.weight}
                        onChange={handleChange}
                        sx={{ mb: 3 }}
                    />

                    {error && (
                        <Typography color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    <Button
                        fullWidth
                        variant="contained"
                        type="submit"
                        disabled={isLoading || !hasChanges()}
                        sx={{
                            bgcolor: '#E78E3A',
                            color: 'white',
                            '&:hover': { 
                                bgcolor: '#061751',
                                color: 'white'
                            },
                            height: 48,
                            '&.Mui-disabled': {
                                bgcolor: '#ccc',
                                color: '#666'
                            }
                        }}
                    >
                        {isLoading ? t.common.saving : t.common.save}
                    </Button>
                </form>
            </Box>
        </ThemeProvider>
    );
}

export default EditPet; 