import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { usePetContext } from '../context/PetContext';
import Loading from './Loading';
function LandingPage({ toggleLanguage, language }) {
  const { setUserPetInfo, setPetsData } = usePetContext();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserPetInfo = async () => {
      try {
        const attributes = await fetchUserAttributes();
        const userId = attributes.sub;
        
        const response = await fetch('https://vjdpdy1eqi.execute-api.us-east-2.amazonaws.com/UAT', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId: userId
          })
        });
        console.log('response', response);
        if (!response.ok) {
          throw new Error('Failed to fetch user pet information');
        }

        const data = await response.json();
        
        // Parse the pets data from the body property
        const petsData = JSON.parse(data.body);
        console.log('petsData', petsData);
        // Sort the data by date (oldest first)
        let sortedPetsData = null;
        if (petsData && petsData.length > 0) {
          sortedPetsData = petsData.sort((a, b) => {
            const dateA = new Date(a.Time);
            const dateB = new Date(b.Time);
            return dateA - dateB;
          });
          setUserPetInfo(sortedPetsData[0]);
          setPetsData(sortedPetsData);
        }

        // Navigate based on whether user has pets or not
        if (sortedPetsData && sortedPetsData.length > 0) {
          navigate('/home');
        } else {
          navigate('createpet');
        }
      } catch (error) {
        console.error('Error fetching user pet information:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchUserPetInfo();
  }, [setUserPetInfo, setPetsData]);

  return (
    <Loading />
  );
}


export default LandingPage;